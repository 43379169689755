import { Auth, Hub, Logger } from "aws-amplify";
import jwt from "jwt-simple";

const logger = new Logger("AuthService");

const CognitoService = {};

CognitoService.signInEmailRequest = async (email, password) =>
  await Auth.signIn(email.toLowerCase(), password)
    .then(user => user)
    .catch(err => err);

CognitoService.signUpEmailRequest = async (email, password) =>
  await Auth.signUp({
    username: email.toLowerCase(),
    password: password,
    attributes: {
      email: email,
      "custom:premium": "0"
    }
  })
    .then(user => user)
    .catch(err => err);

CognitoService.confirmSignUp = async (email, code) =>
  await Auth.confirmSignUp(email.toLowerCase(), code)
    .then(user => user)
    .catch(err => err);

CognitoService.resendConfirmationCode = async email =>
  await Auth.resendSignUp(email.toLowerCase())
    .then(user => user)
    .catch(err => err);

CognitoService.forgotPassword = async email =>
  await Auth.forgotPassword(email.toLowerCase())
    .then(user => user)
    .catch(err => err);

CognitoService.forgotPasswordSubmit = async user =>
  await Auth.forgotPasswordSubmit(
    user.email.toLowerCase(),
    user.code,
    user.password
  )
    .then(user => {
      return true;
    })
    .catch(error => error);

CognitoService.currentSession = async () =>
  await Auth.currentAuthenticatedUser()
    .then(user => {
      console.log(user);
      return user;
    })
    .catch(err => {
      return false;
    });

CognitoService.signOutRequest = async () =>
  await Auth.signOut()
    .then(user => user)
    .catch(err => err);

export default CognitoService;
