import React, { useRef } from "react";

const Gradient = props => {
  const script = document.createElement("script");

  script.src = "/assets/js/gradient.min.js";

  script.type = "text/javascript";
  script.async = true;

  document.body.appendChild(script);
  const canvasRef = useRef(null);
  const canvas = canvasRef.current;

  return <canvas id="gradient-canvas" ref={canvasRef} {...props} />;
};

export default Gradient;
