import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  USER_SIGNUP,
  SHOW_LOADING,
  CONFIRM_SIGNUP_SUCCESS,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED
} from "../constants/Auth";

const initState = {
  loading: false,
  confirm: false,
  message: "",
  showMessage: false,
  user: localStorage.getItem(USER_SIGNUP),
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN),
  currentSession: localStorage.getItem(AUTH_TOKEN),
  premium: localStorage.getItem("premium")
};

const auth = (state = initState, action) => {
  console.log(action);
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
        currentSession: action.token,
        premium: action.premium
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        confirm: true,
        redirect: "/auth/confirm",
        user: action.user
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case CONFIRM_SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        confirmCode: true,
        confirm: false,
        redirect: "/auth/login"
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token
      };
    }
    default:
      return state;
  }
};

export default auth;
