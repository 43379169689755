import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import CognitoService from "services/CognitoService";
import PlaglyService from "services/PlaglyService";
// async function checkAuthState(props) {
//   console.log("qut");
//   try {
//     const session = await CognitoService.currentSession();
//     console.log(session);
//     if (!session) window.location.reload;
//   } catch (err) {
//     console.log("ASasdasdas");
//     props.history.push("12312312");
//   }
// }
// const callApi = () => {
//   PlaglyService.getDocuments().then(resp => {
//     console.log("resp", resp);
//     this.setState({
//       documents: resp
//     });
//   });
// };

function RouteInterceptor({ children, isAuthenticated, premium, ...rest }) {
  // callApi();

  return (
    <Route
      {...rest}
      render={({ location, premium }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Views = props => {
  const { locale, token, premium, location, currentSession } = props;
  console.log("HAHAHAHAH PROPS");
  console.log(props);

  // checkAuthState(props);

  const currentAppLocale = AppLocale[locale];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            <RouteInterceptor isAuthenticated={token}>
              <AppLayout premium={premium} location={location} />
            </RouteInterceptor>
          </Route>
          <Route exact path="/example">
            <AuthLayout />
          </Route>
          <Route path={AUTH_PREFIX_PATH} location={location}>
            <AuthLayout />
          </Route>
          <RouteInterceptor isAuthenticated={token}>
            <AppLayout premium={premium} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth, documents }) => {
  const { locale } = theme;
  const { token, currentSession, premium, user } = auth;

  return { locale, token, premium, user, documents, currentSession };
};

export default withRouter(connect(mapStateToProps)(Views));
