import axios from "axios";
import axiosRetry from "axios-retry";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
});
// axiosRetry(service, {
//   retries: 10, // number of retries
//   retryDelay: (retryCount: number) => {
//     return retryCount * 2000; // time interval between retries
//   },
//   retryCondition: (error: any) => {
//     // if retry condition is not specified, by default idempotent requests are retried
//     return error.response.status === 502;
//   }
// });

const sleepRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(service(originalRequest)), milliseconds);
  });
};

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "Authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  error => {
    // Do something with request error here
    notification.error({
      message: "Error"
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;
    let notificationParam = {
      message: ""
    };

    // Remove token and redirect
    if (
      error.response.status === 400 ||
      error.response.status === 401 ||
      error.response.status === 403
    ) {
      notificationParam.message = "Authentication Fail";
      notificationParam.description = "Please login again";
      localStorage.removeItem(AUTH_TOKEN);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
    }
    if (error.response.status === 502) {
      console.log("ERROR 502");
      return sleepRequest(5000, originalRequest);
    }
    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
