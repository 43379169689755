const dev = {
  API_ENDPOINT_URL: "http://localhost:3001",
  WS_ENDPOINT_URL: "ws://localhost:3003"
};

const prod = {
  API_ENDPOINT_URL: "https://1227n861ck.execute-api.us-east-1.amazonaws.com",
  WS_ENDPOINT_URL: "wss://hwpz6rmiq5.execute-api.us-east-1.amazonaws.com/dev"
};

const test = {
  API_ENDPOINT_URL: "http://localhost:3001"
};

console.log(process.env.REACT_APP_STAGE);

const getEnv = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
