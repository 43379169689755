/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify.

const media_analysis_config = {
  SOLUTION_REGION: "us-east-1",
  SOLUTION_USERPOOLID: "us-east-1_nOXFuUUah",
  SOLUTION_USERPOOLWEBCLIENTID: "45eabechptm78h3v6lb4cvbl7i",
  SOLUTION_IDENTITYPOOLID: "us-east-1:01333ed8-4032-49d0-b311-a19e9b3fdcc7",
  SOLUTION_BUCKET: "media-analysis-us-east-1-745930104157",
  SOLUTION_ENDPOINT:
    "https://00hlpm4qrc.execute-api.us-east-1.amazonaws.com/prod",
  SOLUTION_CONSOLE_LINK: ""
};

const plagly_api_config = {
  SOLUTION_REGION: "us-east-1",
  SOLUTION_USERPOOLID: "us-east-1_nOXFuUUah",
  SOLUTION_USERPOOLWEBCLIENTID: "45eabechptm78h3v6lb4cvbl7i",
  SOLUTION_IDENTITYPOOLID: "us-east-1:01333ed8-4032-49d0-b311-a19e9b3fdcc7",
  SOLUTION_BUCKET: "media-analysis-us-east-1-745930104157",
  SOLUTION_ENDPOINT:
    "https://hwvszhvic4.execute-api.us-east-1.amazonaws.com/dev",
  SOLUTION_CONSOLE_LINK: ""
};

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:01333ed8-4032-49d0-b311-a19e9b3fdcc7",
  aws_user_pools_id: "us-east-1_nOXFuUUah",
  aws_user_pools_web_client_id: "45eabechptm78h3v6lb4cvbl7i",
  aws_dynamodb_all_tables_region: "us-east-1",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_user_files_s3_bucket: "media-analysis-us-east-1-745930104157",

  Auth: {
    region: media_analysis_config.SOLUTION_REGION,
    userPoolId: media_analysis_config.SOLUTION_USERPOOLID,
    userPoolWebClientId: media_analysis_config.SOLUTION_USERPOOLWEBCLIENTID,
    identityPoolId: media_analysis_config.SOLUTION_IDENTITYPOOLID
  },
  Storage: {
    Bucket: media_analysis_config.SOLUTION_BUCKET,
    region: media_analysis_config.SOLUTION_REGION,

    identityPoolId: media_analysis_config.SOLUTION_IDENTITYPOOLID
  },
  API: {
    endpoints: [
      {
        name: "MediaAnalysisApi",
        region: media_analysis_config.SOLUTION_REGION,
        endpoint: media_analysis_config.SOLUTION_ENDPOINT
      },
      {
        name: "PlaglyApi",
        region: plagly_api_config.SOLUTION_REGION,
        endpoint: plagly_api_config.SOLUTION_ENDPOINT
      }
    ]
  }
};

export default awsmobile;
