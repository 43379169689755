import { DOCUMENTS } from "../constants/Documents";

const initState = {
  test: "yay"
};

const documents = (state = initState, action) => {
  switch (action.type) {
    case DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: action.documents
      };

    default:
      return state;
  }
};

export default documents;
