import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  CONFIRM_SIGNUP,
  USER_SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  confirmEmailSignUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated
} from "../actions/Auth";
import { docs } from "../actions/Documents";
import FirebaseService from "services/FirebaseService";
import CognitoService from "services/CognitoService";
import PlaglyService from "services/PlaglyService";

export function* currentSession() {
  try {
    const session = yield call(CognitoService.currentSession);
    if (session) {
      localStorage.setItem(
        "premium",
        session.attributes["custom:premium"] == "1" ? true : false
      );
      localStorage.setItem(
        AUTH_TOKEN,
        session.signInUserSession.idToken.jwtToken
      );
      yield put(
        authenticated(
          session.signInUserSession.idToken.jwtToken,
          session.attributes["custom:premium"] == "1" ? true : false
        )
      );
    } else {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess(session));
    }
  } catch (err) {
    yield put(showAuthMessage(err));
  }
}

export function* signIn() {
  yield takeEvery(SIGNIN, function*({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        CognitoService.signInEmailRequest,
        email,
        password
      );
      console.log(user);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(
          AUTH_TOKEN,
          user.signInUserSession.idToken.jwtToken
        );
        yield put(authenticated(user.signInUserSession.idToken.jwtToken));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithEmail() {
  yield takeEvery(SIGNUP, function*({ payload }) {
    const { email, password } = payload;
    console.log("KASKAKLAKLAKLAKLAKLKL 23482388239489");
    console.log(payload);
    try {
      const user = yield call(
        CognitoService.signUpEmailRequest,
        email,
        password
      );
      console.log(user);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(USER_SIGNUP, user.user.username);
        yield put(signUpSuccess(user.user.username));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* confirmSignUp() {
  yield takeEvery(CONFIRM_SIGNUP, function*({ payload }) {
    const { email, code } = payload;
    try {
      const user = yield call(CognitoService.confirmSignUp, email, code);
      console.log(user);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        yield put(confirmEmailSignUpSuccess(user));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function*({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signInEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(authenticated(user.user.uid));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function*() {
    try {
      const signOutUser = yield call(CognitoService.signOutRequest);
      if (signOutUser === undefined) {
        localStorage.removeItem(AUTH_TOKEN);
        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function*({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function*() {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function*() {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(currentSession),
    fork(signUpWithEmail),
    fork(confirmSignUp),
    fork(signOut)
  ]);
}
