import interceptor from "auth/FetchInterceptor";
import notificationData from "assets/data/notification.data.json";
import Axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";

const plaglyService = {};

const poll = async function(fn, fnCondition, ms) {
  console.log("AJSJAS");

  let result = await fn();
  console.log(result);
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
};

const wait = function(ms = 5000) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

plaglyService.getDocuments = async function(data) {
  console.log("Fetching documents");
  const response = await interceptor.get(API_BASE_URL + "/dev/documents", {
    headers: {
      "Content-Type": "application/json"
      // Authorization: "Bearer " + localStorage.getItem("auth_token")
    }
  });
  console.log("Documents:", response);
  return response.items;
};

plaglyService.getReports = async function(data) {
  console.log("Fetching reports");
  const response = await interceptor.get(API_BASE_URL + "/dev/reports", {
    headers: {
      "Content-Type": "application/json"
    }
  });
  console.log("Reports:", response);
  return response.items;
};

plaglyService.getReport = async function(data) {
  console.log("Fetching reports");

  const reportData = await interceptor.get(
    API_BASE_URL + "/dev/report/" + data.reportId,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  //console.log(await fetchReport());
  //let validate = result => !result;

  // let validate = result => {
  //   console.log(result);
  //   console.log(result.report.scrapedCount === result.report.totalQueries);
  //   return result.report.scrapedCount === result.report.totalQueries
  //     ? false
  //     : true;
  // };
  //  result.report.scrapedCount !== result.report.totalQueries ? true : false;
  //result.report.scrapedCount !== result.report.totalQueries || !result;
  //let response = await poll(fetchReport, validate, 15000);

  console.log("Report:", reportData);
  return reportData.report;
};

plaglyService.getNotifications = async function(data) {
  console.log("Fetching Notifications");
  // const response = await interceptor.get(
  //   `https://jvpbmpkg75.execute-api.us-east-1.amazonaws.com/dev/documents`,
  //   {
  //     headers: {
  //       "Content-Type": "application/json"
  //       // Authorization: "Bearer " + localStorage.getItem("auth_token")
  //     }
  //   }
  // );
  const response = notificationData;
  console.log("Notifications:", response);
  return response;
};

plaglyService.createDocument = async function(data) {
  console.log("Creating Document");
  const response = await interceptor.post(
    `${API_BASE_URL}/dev/documents`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log("Created document:", response);
  return response;
};

plaglyService.getReportText = async function(data) {
  console.log("Getting report text");
  const response = await interceptor.get(
    API_BASE_URL + "/dev/text/" + data.reportId,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log("Report text:", response);
  return response;
};

plaglyService.spellChecker = async function(data) {
  console.log("Spellchecking Document");
  const response = await interceptor.post(
    `${API_BASE_URL}/dev/checkSpelling`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log("Spellchecked document:", response);
  return response;
};

plaglyService.generateUploadUrl = async function(data) {
  const response = await interceptor.post(
    `${API_BASE_URL}/dev/documents/attachment`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log(response);
  return response;
};

plaglyService.extract = async function(data) {
  console.log(data);
  const response = await interceptor.post(
    `${API_BASE_URL}/dev/extract`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log(response);
  return response;
};

plaglyService.getBlake = async function(data) {
  console.log(data);
  const response = await interceptor.get(
    `${API_BASE_URL}/dev/blake`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log(response);
  return response;
};

plaglyService.getTotals = async function(data) {
  console.log(data);
  const response = await interceptor.get(
    `${API_BASE_URL}/dev/totals`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
  console.log(response);
  return response.totals;
};

export default plaglyService;
