import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Documents from "./Documents";
import Comments from "./Comments";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  documents: Documents,
  comments: Comments
});

export default reducers;
