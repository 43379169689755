import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useHistory } from "react-router";

export const AppViews = ({ premium }) => {
  let history = useHistory();
  console.log(premium);
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          exact
          path={`/`}
          component={lazy(() => import(`./apps/check`))}
        />
        <Route
          exact
          path={`/test`}
          component={
            premium
              ? lazy(() => import(`./apps/e-commerce/add-product`))
              : history.push("/upgrade")
          }
        />
        <Route
          path={`/documents`}
          component={lazy(() => import(`./apps/e-commerce/product-list`))}
        />
        <Route
          path={`/medical`}
          component={lazy(() => import(`./apps/medical`))}
        />
        <Route
          exact
          path={`/media`}
          component={lazy(() => import(`./apps/media/browse`))}
        />
        <Route
          exact
          path={`/media/result/:id`}
          component={lazy(() => import(`./apps/media`))}
        />
        <Route
          exact
          path={`/media/upload`}
          component={lazy(() => import(`./apps/media/upload`))}
        />
        <Route path={`/blake`} component={lazy(() => import(`./apps/blake`))} />
        <Route
          path={`/upload`}
          component={lazy(() => import(`./apps/upload`))}
        />
        <Route
          path={`/report/:id`}
          component={lazy(() => import(`./apps/plagiarism/report`))}
        />
        <Route
          path={`/reports`}
          component={lazy(() => import(`./apps/plagiarism/report-list`))}
        />
        <Route
          path={`/editor`}
          component={lazy(() => import(`./apps/editor/containers`))}
        />
        <Route path={`/chat`} component={lazy(() => import(`./apps/chat`))} />
        <Route
          path={`/ecommerce`}
          component={lazy(() => import(`./apps/e-commerce`))}
        />
        <Route
          path={`/upgrade`}
          component={lazy(() => import(`./pages/pricing`))}
        />
        <Route
          path={`/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
